@import "../styles/font";

$margin-base: 16px;
$padding-base: 16px;
$base-font-size: 16;
$base-line-height: 16px;
$font-unit: rem;

// region BREAKPOINTS  // these need to be removed......
$breakpoints: (
  mbl: 639px,
  tbl-p: 959px,
  tbl-l: 960px,
  dsk: 1440px,
);

//new breakpoints based on design which currently will use
$breakpoint-xsm: 320px;
$breakpoint-sm: 568px;
$breakpoint-m: 1024px;
$breakpoint-l: 1366px;
$breakpoint-w: 1920px;

// endregion BREAKPOINTS // these need to be removed......
$media-query-gap: 1px;
$grid-breakpoint--xs: 0;
$grid-breakpoint--sm: 576px;
$grid-breakpoint--md: 768px;
$grid-breakpoint--lg: 992px;
$grid-breakpoint--xl: 1440px;
$grid-breakpoint--xxl: 1600px;
// endregion BREAKPOINTS
// region COLORS
// Color & naming defined by design
$color-white: #fff;
$color-backgroud-grey: #f4f4f4;
$color-lightest-grey: #f4f4f4;
$color-background-grey-diluted: #fbfbfb;
$color-light-grey: #d1d2d4;
$color-medium-grey: #abadaf;
$color-dark-grey: #4c4c4e;
$color-light-black: #1e1e1d;
$color-black: #000;
$color-error: #c71515;
$color-warning: #de9521;
$color-success: #40ab00;
$color-primary-red: #a71930;
$color-dark-red: #8b0018;
$color-brown: #a59a8f;
$color-header-shadow: rgba(0, 0, 0, 0.3);
$color-button-shadow: rgba(126, 126, 126, 0.25);
$color-button-shadow-hover: rgba(0, 0, 0, 0.3);
$color-tooltip-shadow: rgba(0, 0, 0, 0.4);
$color-black-transparent: rgba(0,0,0,0.4);
$color-white-transparent: rgba(255,255,255,0.4);

$color-757575: #757575;
$color-1a61ff: #1a61ff;
$color-9E9E9E: #9e9e9e;
$color-EEEEEE: #eeeeee;
$color-f5f5f5: #f5f5f5;
$color-E5E5E5: #e5e5e5;
$color-1554FF: #1554ff;
$color-232323: #232323;
$color-0048E6:#0048E6;
$color-424242:#424242;
$color-616161:#616161;
$color-e0e0e0: #e0e0e0;
$color-0041CE:#0041CE;
$color-0048E5:#0048E5;
$color-0092D6:#0092D6;
$color-BDBDBD: #BDBDBD;

// Custom colors
$black: #000000;

$gray-dark: #424242;
$gray-light: #e4e4e4;
$gray-F5F5F5: #f5f5f5;

$primary-button: #1a61ff;
$primary-button-hover: #0048e5;
$primary-button-pressed: #0041ce;

$secondary-button: #000;
$secondary-button-hover: #424242;
$secondary-button-pressed: #616161;

$ghost-button: #1554ff;
$ghost-button-hover: #0048e5;
$ghost-button-pressed: #0041ce;

$blue: #004b93;
$color-primary: #1554fe;
$color-primary-hover: #0140e9;
$grey-eyebrow: #4c4c4e;
$gray-disabled: #d0d0d0;
$gray-light: #edebe8;
$gray-medium: #ded9d4;
$checkbox-border: #a2a2a2;
$white: #ffffff;
$gray-shadow: rgba(0, 0, 0, 0.1);
$blue: #004b93;
$color-background-cta-hover: #8b0018;
$color-lighter-grey: #eee;
// endregion COLORS
// region Z INDEX
$z-index-base: 1;
$z-index-tooltip: 10;
$z-index-navigation: 100;
$z-index-series-navigation: 99;
$z-index-modal: 1000;
// endregion Z INDEX
$width-max-page: 1680px;
$width-max-modal-content: 1344px;
$height-navigation: 100px;
$height-navigation-mobile: 81px;
$height-series-navigation: 74px;
$font-family-default: sans-serif;
$modal-overlay-background-color: rgba(0, 0, 0, 0.7);
$modal-background-color: #ffffff;
// region TYPOGRAPHY
$font-family: "CodecWarm-Bold", $font-family-default;
$font-family-CodecWarm-ExtraBold: "CodecWarm-ExtraBold", $font-family-default;
$font-family-CodecWarm-Regular: "CodecWarm-Regular", $font-family-default;
$font-family-CodecWarm-ExtraLight: "CodecWarm-ExtraLight", $font-family-default;
$font-family-Opensans-Bold: "Open-Sans-Bold", $font-family-default;
$font-family-Helvetica: "Helvetica", $font-family-default;

$RobotoRegular: "Roboto", $font-family-default;
$RobotoMedium: "Roboto-Medium", $font-family-default;
$RobotoBold: "Roboto-Bold", $font-family-default;
$GillSansLight: "Gill Sans Light", $font-family-default;
$GillSansRegular: "Gill Sans", $font-family-default;
$GillSansMedium: "Gill Sans Medium", $font-family-default;

//Text Colors
$text-white-4: #ffffff;
$bg-color-4: #ffffff;
$CTA-bg-color: #1554fe;
$font-size-extra-small: 10px;
$font-size-smaller: 12px;
$font-size-small: 14px;
$font-size-base: 16px;
$font-size-medium: 18px;
$font-size-large: 22px;
$font-size-large-secondary: 30px;
$font-size-larger: 40px;
$font-size-extra-large: 70px;
$line-height-extra-small: 12px;
$line-height-smaller: 16px;
$line-height-small: 20px;
$line-height-base: 22px;
$line-height-medium: 25px;
$line-height-large: 30px;
$line-height-larger: 46px;
$line-height-extra-large: 74px;
// endregion TYPOGRAPHY
// region Z-INDEXES
$z-index-base: 0;
$z-index-bg-image-need-support: 3;
$z-index-content-need-support: 7;
$z-index-tooltip: 10;
$z-index-carousel-arrow: 10;
$z-index-navigation: 100;
$z-index-quick-links: 15;
$z-index-modal: 1000;
// endregion Z-INDEXES
