@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/@shared/flexbox";
@import "../../styles/@shared/grid";

.hideSection {
  display: none !important;
}

.red {
  color: #da1414 !important;
}

.disclaimer {
  float: left;
  margin-top: 20px !important;
  display: block;
}

.backToConfigurator {
  position: absolute !important;
  top: 0;
  z-index: 999;
  right: 0;

  button {
    background-color: transparent !important;
  }

  button:after {
    content: "\00d7";
    font-size: 35px;
    color: black;
    width: 100px;
    height: 100px;
    cursor: pointer;
  }

  @media screen and (max-width: ($breakpoint-sm - 0.02)) {
    button:after {
      color: white !important;
    }
  }
}

@media screen and (min-width: ($breakpoint-sm - 0.02)) {
  .form_right_section.twoCols {
    margin-left: 16px !important;
    max-width: calc(50% - 16px) !important;
  }

  .form_full_section.twoCols {
    max-width: calc(100% - 16px) !important;
    width: calc(100% - 16px) !important;
  }
}

.configuratorInput {
  visibility: hidden !important;
}

.configuratorSubmitButton button {
  height: 52px !important;
}

label + input.configuratorInput {
  display: none;
}

.formTurkey {
  padding-right: 20px !important;
  display: block;
  padding-top: 10px;
  width: 100% !important;
  max-width: 100% !important;
  flex-basis: 100% !important;

  fieldset .invalid:not(label) {
    top: -32px !important;
  }

  fieldset:has(textarea) .invalid:not(label) {
    top: -25px !important;
  }

  .form_full_section > textarea,
  .form_full_section {
    clear: both !important;
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 0px !important;
  }

  @media screen and (min-width: ($breakpoint-sm - 0.02)) {
    .form_right_section.twoCols {
      margin-left: 16px !important;
      max-width: calc(50% - 16px) !important;
    }

    .form_full_section.twoCols {
      max-width: calc(100% - 16px) !important;
      width: calc(100% - 16px) !important;
    }
  }

  textarea {
    background-color: #fafafa;
    padding-left: 17px;
    height: 100px;
    width: 100%;
  }

  .privacyTurkey {
    width: 100% !important;
    max-width: 100% !important;
    border: 0px !important;
    flex-basis: 100% !important;

    .form_privacy_section {
      display: flex;
      flex-direction: column;
    }

    .form_privacy_section label {
      order: -1;
      /* Imposta l'ordine a -1 per posizionare la label a sinistra */
      margin-right: 10px;
      /* Aggiunge uno spazio a destra della label */
    }

    .form_html_label {
      order: 1;
      /* Imposta l'ordine a 1 per posizionare lo span a destra */
      margin-left: 25px;
      /* Aggiunge uno spazio a sinistra dello span */
    }

    .sitecore-jss-form__error-list {
      top: 52px !important;
      /* Aggiunge uno spazio sopra il div degli errori */
    }

    textarea.sitecore-jss-form__error-list {
      top: -29px !important;
      /* Aggiunge uno spazio sopra il div degli errori */
    }

    .form_privacy_section {
      &::after {
        content: "";
        height: 0px !important;
      }
    }

    .form_privacy_section > .form_privacy_section {
      .form_html_label {
      }
    }

    fieldset {
      padding-top: 20px !important;
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
      height: auto !important;
      margin-bottom: 5px;
      border: 0px !important;
      padding-right: 0px !important;

      label {
        border: 0px !important;
        left: 0 !important;
      }

      span {
        margin-left: 25px;
        float: left;
        border: 0px !important;
        margin-top: -8px;
      }
    }
  }

  fieldset:has(textarea) {
    border-top: 1px solid #fafafa;

    p {
      color: $color-1554FF;
      margin-bottom: 40px;
      margin-top: 20px;
    }
  }

  fieldset {
    textarea {
      background: #fafafa;
      padding-top: 10px;
      padding-left: 20px;
      outline: none;
      height: 144px;
      width: 100%;
    }
  }

  fieldset.active {
    textarea {
      border: 2px solid $color-1554FF;
      background-color: white;
    }
  }

  fieldset.success-input {
    textarea {
      border: 1px solid #287d3c;
    }
  }

  fieldset:has(textarea) {
    position: relative;
    padding-right: 0;
  }

  fieldset.active:has(textarea) {
  }

  .submit_section {
    float: left;
    margin-bottom: 20px !important;
    display: block;
  }
}

.loginFormPromo {
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    height: 50px;
    outline: 0;
    background-color: $color-white;
    background-image: none;
    color: #555;
    display: inline-block;
    padding: 6px 20px;
    width: 100%;
    transition: ease-in-out 0.15s;
    border: 1px solid #605e5c;
    border-radius: 8px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  input:focus {
    border: 2px solid $color-black;
  }

  button {
    margin-left: auto;
    margin-right: auto;
    background-color: #296ec6;
    border-radius: 8px;
    font-weight: 400;
    padding: 10px 16px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    color: $color-white;
    margin-top: 30px;
    margin-bottom: 16px;
    width: 63%;
    cursor: pointer;
  }

  .disabled {
    background-color: #5193e8;
  }

  .disabled:hover {
    background-color: #5193e8;
  }

  .disabled:active {
    background-color: #5193e8;
  }

  button:hover {
    background-color: #1662c7;
  }

  button:active {
    background-color: #1662c7;
  }
}

.formBrazil {
  padding-right: 0px !important;
  display: block;
  padding-top: 10px;
  width: 100% !important;
  max-width: 100% !important;
  flex-basis: 100% !important;

  .sitecore-jss-form__error-list,
  .itecore-jss-form__error-list {
    max-width: 100% !important;
    flex-basis: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    top: -32px !important;

    p {
      max-width: 100% !important;
      flex-basis: 100% !important;
      padding-right: 0px !important;
      margin: 0px !important;
      width: 100% !important;
    }

    fieldset:has(.error-input) > div.sitecore-jss-form__error-list {
      .itecore-jss-form__error-list {
        border: 3px solid blue;
      }
    }
  }

  fieldset:not(.privacy_section):not(.itecore-jss-form__error-list) {
    display: block;
    width: 50%;
    float: left;
    max-width: 50%;
    margin: 0;
    padding-right: 16px !important;
    height: 80px !important;
  }

  .form_full_section {
    clear: both !important;
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 0px !important;
  }

  .privacy_section {
    padding: 0px !important;
    margin-top: 10px !important;
    display: flex !important;
    width: 100% !important;
    height: auto;
    min-height: 20px;
    max-height: 104px;
    max-width: 100% !important;
    flex-basis: 100% !important;

    label {
      top: 0px !important;
      left: 0px !important;
      width: auto !important;
      height: 100% !important;
      position: relative !important;
      float: left !important;
    }

    .sitecore-jss-form__error-list {
      position: absolute;
      top: 26px !important;
      width: 100%;
    }
  }

  .privacy_section:has(.sitecore-jss-form__error-list) {
    min-height: 50px;
  }

  .form_privacy_section::after {
    background-color: #eee;
    position: absolute;
    height: 0px !important;
    width: 100%;
  }

  .form_left_section {
    padding-right: 16px !important;
    padding-left: 0;
  }

  .form_right_section {
    padding-right: 0;
    padding-left: 0;
  }

  .form_consent_version {
    display: none !important;
  }
}

.form.contactUs > form {
  width: 100%;
  padding-left: 48px;
  margin-right: 0;
}

.form {
  fieldset {
    .PhoneInput {
      .PhoneInputCountry {
        opacity: 0;
        position: absolute;
        top: 15px;
        left: 17px;
      }

      input {
        padding-left: 60px !important;
      }
    }

    &.active .PhoneInputCountry {
      opacity: 1;
    }
  }

  &.form-rtl {
    fieldset {
      .PhoneInput {
        input {
          padding-left: 0 !important;
          padding-right: 60px !important;
          text-align: right;
        }
        .PhoneInputCountry {
          margin-right: 0;
          margin-left: 0.35rem;
          right: 17px;
          left: inherit;

          select {
            padding-left: 0;
            padding-right: 29px;
          }

          .PhoneInputCountrySelectArrow {
            margin-left: 0;
            margin-right: 0.35rem;
          }
        }
      }
    }
  }
}

.form form button.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}

section .form.dark {
  background-color: #000000;
  color: #fff;

  fieldset {
    p {
      color: #fff;
    }

    h5 {
      color: #fff;
    }

    label {
      color: #000000;
    }
  }
}

main section.form,
.ReactModalPortal section.form {
  // position: relative; //already present
  div#spinnerForm {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 999;

    img {
      position: absolute;
      top: 80%;
      right: 50%;
    }
  }
}

.form.contactUs
  > form
  > fieldset:nth-child(even):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
    .form_privacy_section_marketing
  ):not(.form_title_section) {
  padding-left: 0;
}

.form.contactUs
  > form
  > fieldset:nth-child(odd):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
    .form_privacy_section_marketing
  ):not(.form_title_section) {
  padding-left: 0;

  label {
    left: 16px;
  }

  .formlanguagesreload .label {
    left: 6px;
  }
}

.form.form-rtl.contactUs
  > form
  > fieldset:nth-child(odd):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
    .form_privacy_section_marketing
  ):not(.form_title_section) {
  padding-left: 0;

  label {
    right: 16px;
    left: inherit;
  }

  .formlanguagesreload .label {
    right: 6px;
    left: inherit;
  }
}

.form.contactUs > form > fieldset:nth-child(n + 7):not(.form_privacy_section):not(.form_title_section) {
  padding-right: 0;
  padding-left: 0;
}

.form {
  .form_section > p,
  form > p {
    @include font-sizes($RobotoRegular, 13, 17);
    color: $color-757575;
    // padding-left: 30px;
    padding-left: 34px;
  }

  .privacy-title {
    @include font-sizes($RobotoRegular, 13, 17);
    color: #424242;
    cursor: pointer;
    margin-bottom: 0;
    font-weight: normal;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .invalid {
    position: relative;
    top: 0;
    margin: 0;

    p {
      pfe-icon {
        display: none;
      }

      @include font-sizes($RobotoRegular, 13, 17);
      background: #feefef;
      color: #da1414;
      padding: 3px 16px;
    }
  }

  fieldset {
    @include column(12);

    @media screen and (min-width: ($breakpoint-sm - 0.02)) {
      input:not(:nth-child(1)) {
        background-color: #fafafa;
        padding-left: 17px;
        margin-bottom: 27px;
        height: 48px;
        width: 100%;

        &:disabled {
          border: 1px solid #757575;
          background: #f4f6f9;
          opacity: 0.5;
        }
      }
    }

    @media screen and (max-width: ($breakpoint-sm - 0.02)) {
      input:not(:nth-child(1)) {
        background-color: #fafafa;
        padding-left: 17px;
        margin-bottom: 8px;
        height: 48px;
        width: 100%;

        &:disabled {
          border: 1px solid #757575;
          background: #f4f6f9;
          opacity: 0.5;
        }
      }
    }

    &:not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(.form_privacy_section_marketing) label {
      @include font-sizes($RobotoRegular, 16, 24);
      position: absolute;
      margin-left: 16px;
      top: 11px;
      left: 0;
      transition: top 0.25s;
    }

    button {
      padding: 14px 20px;
      @include font-sizes($RobotoRegular, 18, 24);
      max-width: 100%;
      background-color: $color-1a61ff;
      color: $color-white;
      text-transform: lowercase;
      padding-right: 50px;
      position: relative;

      svg {
        position: absolute;
        top: 26%;
        right: 15px;
      }

      &:active {
        background-color: $color-0041CE;
      }
    }

    button::first-letter {
      text-transform: capitalize;
    }

    @media screen and (max-width: ($breakpoint-sm - 0.02)) {
      .invalid:not(label) {
        position: relative;
        top: -10px;

        p {
          background: #feefef;
          color: #da1414;
          padding: 3px 16px;
          margin-left: 0;
        }
      }
    }

    @media screen and (min-width: ($breakpoint-m - 0.02)) {
      .invalid:not(label) {
        position: relative;
        top: -29px;

        p {
          background: #feefef;
          color: #da1414;
          padding: 3px 16px;
          margin-left: 0;
        }
      }
    }
  }

  fieldset.form_privacy_section_marketing div.invalid {
    position: relative;
    top: 4px;
    width: 100%;
  }

  fieldset.form_privacy_section_marketing_third_party div.invalid {
    position: relative;
    top: 4px;
    width: 100%;
  }

  .form_privacy_section_marketing_third_party {
    position: relative;
    display: flex;
    flex-flow: wrap !important;
    background-repeat: no-repeat;
    background-position: right;

    label:not(:first-child) {
      font-size: 13px;
    }

    label:first-of-type {
      @include font-sizes($RobotoRegular, 13, 17);
      color: $color-757575;
    }

    input {
      cursor: pointer;
    }
  }

  &.form-rtl {
    input[type="radio"] {
      margin-right: 0;
      margin-left: 12px;

      &::before {
        left: auto;
        right: 0;
      }

      &:checked::after {
        left: auto;
        right: 0;
      }
    }

    button {
      padding: 14px 20px;
      @include font-sizes($RobotoRegular, 18, 24);
      max-width: 100%;
      background-color: $color-1a61ff;
      color: $color-white;
      text-transform: lowercase;
      padding-right: 20px !important;
      position: relative;
      display: flex;

      svg {
        position: relative;
        top: auto;
        right: auto;
        margin-right: 10px;
        transform: scaleX(-1);
      }

      &:active {
        background-color: $color-0041CE;
      }
    }
  }

  input[type="radio"] {
    border: none;
    height: 16px;
    margin-right: 12px;
    margin-left: 0;
    width: 16px;

    &[disabled] {
      opacity: 0.6;
      pointer-events: none;
    }

    &::before {
      border: 1px solid rgba(11, 95, 255, 0.5);
      background-color: #fff;
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      top: 0;
      left: 0;
    }

    &:checked::after {
      content: "";
      border: 4.5px solid $color-1554FF;
      background-color: $color-white;
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 50%;
    }
  }

  .form_privacy_section_marketing {
    position: relative;
    display: flex;
    flex-flow: wrap;
    background-repeat: no-repeat;
    background-position: right;

    svg {
      display: block;
    }

    h5 {
      @include font-sizes($RobotoRegular, 13, 17);
      cursor: pointer;
      color: #424242;
      margin-bottom: 0;
      font-weight: normal;
      width: 100%;
    }

    label:not(:first-child) {
      font-size: 13px;
    }

    label:first-of-type {
      @include font-sizes($RobotoRegular, 13, 17);
      color: $color-757575;
    }

    label:not(:first-of-type) {
      margin-top: 10px;
    }

    input {
      cursor: pointer;
    }

    .invalid {
      p {
        margin-top: 0 !important;
      }
    }
  }

  .privacy_section label {
    position: relative;
    transition: 0.1s all ease;

    &:not(:first-of-type) {
      cursor: pointer;
      margin-top: 10px;
    }

    &:first-child:hover:after {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      left: -12px;
      border-radius: 50%;
      top: -12px;
    }

    &:first-child:hover input[type="checkbox"]:after {
      background: rgba(0, 84, 240, 0.1);
    }

    &:first-child:active input[type="checkbox"]:after {
      background: rgba(0, 75, 214, 0.2);
    }

    &:first-child:hover:after {
      background: rgba(0, 84, 240, 0.1);
    }

    &:first-child:active::after {
      background: rgba(0, 75, 214, 0.2);
    }

    &:not(:first-of-type):hover:before,
    &:not(:first-of-type):active:before {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      left: -12px;
      border-radius: 50%;
      top: -9px;
    }

    &:not(:first-child):hover input[type="radio"]:before,
    &:not(:first-child):active input[type="radio"]:before {
      border: 1px solid #0b5fff;
    }

    &:not(:first-child):hover input[type="radio"]:before {
      background: rgba(0, 84, 240, 0.1);
    }

    &:not(:first-child):active input[type="radio"]:before {
      background: rgba(0, 75, 214, 0.2);
    }

    &:not(:first-child):hover:before {
      background: rgba(0, 84, 240, 0.1);
    }

    &:not(:first-child):active:before {
      background: rgba(0, 75, 214, 0.2);
    }
  }

  fieldset {
    &:not(.form_privacy_section):not(.form_poland_accordion_title_section):not(.form_privacy_section_marketing):not(
        .form_privacy_section_marketing_third_party
      ) {
      &.active {
        input {
          background-color: $color-white;
          outline-color: $color-1554FF;
        }

        label {
          @include font-size(13, 17);
          background-color: $color-white;
          color: $color-1554FF;
          top: -8px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }

    &.success-input:not(.form_privacy_section_marketing):not(.form_privacy_section_marketing_third_party) {
      input {
        border: 1px solid #287d3c;
      }

      &:not(.form_privacy_section):not(.form_privacy_section_marketing):not(.form_privacy_section_marketing_third_party) label {
        background: $color-white;
        color: #287d3c;
      }
    }
  }

  fieldset.error-input {
    input {
      background-color: $color-white;
      border: 1px solid #da1414;
    }
  }

  &.form-rtl {
    fieldset {
      select:not(:nth-child(1)),
      input:not(:nth-child(1)) {
        padding-left: 0;
        padding-right: 17px;
      }
    }
    .form_poland_accordion_content_section label,
    .form_poland_privacy_notice_section label,
    .form_poland_accordion_title label,
    .form_poland_accordion_title_section label,
    .privacy_section label,
    .form_poland_accordion_content_section label {
      &:first-child:hover:after {
        left: auto;
        right: -12px;
      }

      &:not(:first-of-type):hover:before,
      &:not(:first-of-type):active:before {
        top: -11px;
        left: auto;
        right: -12px;
      }

      input[type="checkbox"] {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  .form_privacy_section {
    svg {
      display: none;
    }

    display: flex;

    input[type="checkbox"] {
      border: none;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      z-index: 12;
      position: relative;

      &::before {
        border: 1px solid rgba(11, 95, 255, 0.5);
        background-color: $color-white;
        content: "";
        border-radius: 2px;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
      }

      &:checked::after {
        content: "";
        border: solid $color-white;
        border-width: 0 2px 2px 0;
        position: absolute;
        top: 1px;
        left: 6px;
        height: 10px;
        width: 5px;
        transform: rotate(45deg);
      }

      &:checked::before {
        background-color: $color-1554FF;
      }
    }

    p {
      @include font-sizes($RobotoRegular, 13, 17);
      color: #424242;
    }
  }

  .form_consent_version {
    display: none;
  }

  .form_privacy_section {
    display: flex;
    // border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    position: relative;

    &::after {
      content: "";
      background-color: #eee;
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
    }

    // label::before {
    //   content: "";
    //   position: absolute;
    //   width: 85px;
    //   height: 1px;
    //   background-color: $color-424242;
    //   top: 14px;
    //   margin-left: 112px;
    // }

    a {
      color: $color-424242;
      border-bottom: 1px solid black;
    }

    .form_checkbox_label {
      margin-left: 0;
    }
  }

  p {
    @include font-sizes($RobotoRegular, 13, 17);
    color: $color-757575;
    // margin-left: 12px;
  }

  fieldset.submit_section {
    padding-top: 30px;

    button {
      cursor: pointer;
    }
  }

  .submit_section {
    display: flex;
    justify-content: flex-end;
  }

  .submit_section + p {
    margin-left: 5px;
    padding-left: 0;
    margin-top: 30px;
  }

  &.form-rtl {
    .submit_section + p {
      margin-right: 5px;
      padding-right: 0;
      margin-left: 0;
      padding-left: inherit;
    }
    .form_privacy_section_marketing label:not(:first-of-type) {
      margin-left: 18px;
      margin-right: 0;
    }

    .form_privacy_section_marketing_third_party label:not(:first-of-type) {
      margin-left: 18px;
      margin-right: 0;
    }
  }

  .form_privacy_section_marketing label:not(:first-of-type) {
    margin-right: 18px;
  }

  .form_privacy_section_marketing_third_party label:not(:first-of-type) {
    margin-right: 18px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
  }
}

.form_poland_gdpr_section {
  width: 100% !important;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.commercialLeadPoland .invalid:not(label) {
  margin-top: 4px;
  /* border: 1px solid blue; */
}

.form:has(.form_poland_gdpr_section) {
  .mandatoryCheckParent:has(.sitecore-jss-form__error-list) {
    margin-bottom: 30px;
  }

  .form_poland_gdpr_section fieldset:not(.floatLeft) {
    width: 100% !important;
    max-width: 100% !important;

    label:has(input) {
      left: 0px !important;
    }
  }

  .errorCheckFE {
    display: none;
  }

  h5 {
    font-size: 1.5rem !important;
    line-height: 1.9375rem !important;
  }

  fieldset {
    margin-left: 0px !important;
  }

  fieldset:has(span.labelPoland) {
    margin-bottom: 5px;
  }

  span.labelPoland {
    margin-left: 25px;
    float: left;
    margin-top: 0px;
  }

  .form_title {
    /*
        font-size: 24px !important;
        line-height: 1 !important;
        margin-bottom: 10px;
        */
  }

  .privacy_section div.invalid {
    position: absolute;
    top: 26px;
    width: 100%;
  }

  .collapse-arrow,
  .expanded-arrow {
    cursor: pointer;
  }

  .form_accordion_open,
  .form_accordion_close {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 0px;
    float: left;
    color: #4d85ff;
  }

  .form_poland_accordion_title_section svg {
    margin-top: 0px;
  }

  .form_poland_accordion_title .sitecore-jss-form__error-list {
    /*position: absolute !important;
        top: 26px; */
  }

  .form_poland_accordion_content_section .sitecore-jss-form__error-list {
    position: relative !important;
    top: 0px;
  }

  .form_poland_accordion_content_section label,
  .form_poland_privacy_notice_section label,
  .form_poland_accordion_title label,
  .form_poland_accordion_title_section label,
  .form_poland_accordion_content_section label {
    font-size: 16px !important;
    width: auto !important;
    padding: 0px !important;
    top: 0px !important;
    margin-left: 0px !important;
    transition: 0.1s all ease;

    &:not(:first-of-type) {
      cursor: pointer;
      margin-top: 10px;
    }

    &:first-child:hover:after {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      left: -12px;
      border-radius: 50%;
      top: -12px;
    }

    &:first-child:hover input[type="checkbox"]:after {
      background: rgba(0, 84, 240, 0.1);
    }

    &:first-child:active input[type="checkbox"]:after {
      background: rgba(0, 75, 214, 0.2);
    }

    &:first-child:hover:after {
      background: rgba(0, 84, 240, 0.1);
    }

    &:first-child:active::after {
      background: rgba(0, 75, 214, 0.2);
    }

    &:not(:first-of-type):hover:before,
    &:not(:first-of-type):active:before {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      left: -12px;
      border-radius: 50%;
      top: -9px;
    }

    &:not(:first-child):hover input[type="radio"]:before,
    &:not(:first-child):active input[type="radio"]:before {
      border: 1px solid #0b5fff;
    }

    &:not(:first-child):hover input[type="radio"]:before {
      background: rgba(0, 84, 240, 0.1);
    }

    &:not(:first-child):active input[type="radio"]:before {
      background: rgba(0, 75, 214, 0.2);
    }

    &:not(:first-child):hover:before {
      background: rgba(0, 84, 240, 0.1);
    }

    &:not(:first-child):active:before {
      background: rgba(0, 75, 214, 0.2);
    }
  }

  .form_poland_accordion_content_section,
  .form_poland_accordion_title label {
    margin-left: 0px !important;
  }

  .floatLeft {
    float: left;
    width: auto !important;
    padding-right: 0px !important;
    margin-right: 20px !important;
  }

  .form_poland_contact_by_section {
    fieldset:nth-child(even):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
        .form_privacy_section_marketing
      ):not(.form_title_section) {
      padding-right: 0px !important;
      padding-left: 0 !important;
    }
  }

  .form_poland_accordion_content_section {
    margin-top: 10px !important;
    margin-bottom: 30px !important;

    .acconsento {
      font-size: 18px !important;
      font-weight: 700;
    }

    p {
      margin-left: 18px;
      width: 100%;
    }

    .sitecore-jss-form__error-list p {
      margin-left: 0px;
      margin-top: 20px;
    }

    @media screen and (min-width: ($breakpoint-sm - 0.02)) {
      .sitecore-jss-form__error-list p {
        margin-top: 0px !important;
      }
    }
  }

  .commercialLeadPoland .form_poland_accordion_content_section {
    .sitecore-jss-form__error-list p {
      margin-left: 0px;
      margin-top: 0px;
    }
  }

  .form_poland_accordion_title_section label {
    width: auto;
  }

  display: flex;

  input[type="checkbox"] {
    border: none;
    width: 16px;
    height: 16px;
    z-index: 12;
    position: relative;

    &::before {
      border: 1px solid rgba(11, 95, 255, 0.5);
      background-color: white;
      content: "";
      border-radius: 2px;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
    }

    &:checked::after {
      content: "";
      border: solid white;
      border-width: 0 2px 2px 0;
      position: absolute;
      top: 1px;
      left: 6px;
      height: 10px;
      width: 5px;
      transform: rotate(45deg);
    }

    &:checked::before {
      background-color: #1554ff;
    }
  }

  p {
    @include font-sizes($RobotoRegular, 13, 17);
    color: #424242;
  }

  .form_poland_contact_by_section {
    margin: 0 !important;
    padding: 0 !important;

    p {
      margin-left: 25px !important;
    }

    .sitecore-jss-form__error-list p {
      margin-left: 0px !important;
    }

    label {
      margin: 0 !important;
      padding: 0 !important;
      margin-right: 10px !important;
      float: left !important;
      position: relative !important;
      width: auto !important;
    }

    span.labelPoland {
      margin: 0 !important;
      padding: 0 !important;
      margin-right: 0px !important;
      float: left;
    }
  }

  .right-col:has(.form_poland_gdpr_section) {
    padding: 48px;
    margin: 0px;
    margin-left: -17px !important;
  }

  .section-space:has(.form_poland_gdpr_section) {
    padding-top: 64px;
    padding-bottom: 96px;
    padding: 0px;
    margin: 0px;
  }

  .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="checkbox"]) {
    width: 100%;
    /* color: white; */
  }

  .right-col:has(.form_poland_gdpr_section) fieldset:has(input[type="text"]),
  .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="text"]) {
    width: calc(50% - 35px) !important;
    float: left !important;
    /* color: black; */
  }

  .right-col:has(.form_poland_gdpr_section) fieldset:has(input[type="email"]),
  .right-col:has(.form_poland_gdpr_section)
    fieldset:has(input[type="text"]):nth-child(2)
    .contactUs:has(.form_poland_gdpr_section)
    fieldset:has(input[type="email"]),
  .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="text"]):nth-child(2) {
    width: calc(50% - 35px) !important;
    float: left !important;
    /* color: black; */
  }

  .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="number"]) {
    width: calc(50% - 35px);
    float: left;
    /* color: black;  */

    .light {
      background: white !important;
      /* color: black !important; */
    }
  }

  @media screen and (max-width: ($breakpoint-sm - 0.02)) {
    .form_poland_contact_by_section {
      p {
        margin-left: 25px !important;
        width: calc(100% - 30px) !important;
      }

      fieldset.sitecore-jss-form__error-list p {
        width: calc(100% - 0px) !important;
      }

      label {
        clear: both;
      }

      span.labelPoland {
        margin: 0 !important;
        padding: 0 !important;
        width: auto !important;
        padding-right: 20px !important;
        float: left !important;
      }
    }

    .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="checkbox"]) {
      width: 100%;
      /* color: white; */
    }

    .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="text"]) {
      width: 100%;
      float: none;
      /* color: black; */
    }

    .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="email"]),
    .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="text"]):nth-child(2) {
      width: 100%;
      float: none;
      /* color: black; */
    }

    .contactUs:has(.form_poland_gdpr_section) fieldset:has(input[type="number"]) {
      width: 100%;
      float: none;
      /* color: black;  */

      .light {
        background: white !important;
        /* color: black !important; */
      }
    }
  }

  .form_poland_accordion_title_section {
    position: relative;
  }

  &.page-form {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    .cp-row {
      margin-left: 0;
      margin-right: 0;

      .col-6 {
      }
    }
  }

  .one-col-section .video-gallery-module .videos .video {
    height: 360px !important;
    margin-bottom: 48px !important;
  }

  // &.there-is-sidebar {
  //   background-color: $color-f5f5f5;
  //   .one-col-section + .one-col-section {
  //     background-color: $color-white;
  //   }
  // }
  section.form.container.contactUs form {
    width: 100%;
  }

  position: relative;

  &:not(.there-is-sidebar) {
    &:before {
      content: "";
      height: calc(100% + 1px);
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      position: absolute;
      width: 100vw;
    }
  }

  @media screen and (max-width: ($breakpoint-sm - 0.02)) {
    &.there-is-sidebar .cp-row .one-col-section:first-of-type {
      margin-left: 0 !important;
      margin-right: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }

  @media screen and (max-width: ($breakpoint-m - 0.02)) {
    &.page-form.mobile {
      .col-6 {
        flex-basis: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  // @media screen and (min-width: $breakpoint-m) {
  //   &.there-is-sidebar {
  //       &:before {
  //       content: "";
  //       background-color: $color-f5f5f5;
  //       height: 100%;
  //       left: 0%;
  //       position: absolute;
  //       width: 25%;
  //       z-index: 1;
  //     }
  //   }
  // }

  &.dark {
    .cp-row {
      background-color: $color-black;

      .texting-module {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }

  &.dark:before {
    background-color: $color-black;
  }

  &.blue {
    .cp-row {
      background-color: $color-1554FF;

      .texting-module {
        background-color: $color-1554FF;
        color: $color-white;
      }
    }
  }

  &.blue:before {
    background-color: $color-1554FF;
  }

  .carousel-module {
    margin-top: 0;
    margin-bottom: 0;
  }

  .text-block-module {
    padding: 0;
  }

  fieldset.form_privacy_section_marketing,
  fieldset.form_privacy_section_marketing_third_party {
    // @include column(12);
    margin-top: 30px;
    max-width: 100%;
    flex-basis: 100%;
    // margin-left: 0 !important;
    // border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    display: flex;
    flex-flow: wrap !important;
    flex-direction: column;
    padding-right: 0;

    .expanded-arrow {
      display: none;
    }

    &::before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: #eee;
    }

    .collapse-arrow,
    .expanded-arrow {
      cursor: pointer;
      position: absolute;
      top: 17px;
      right: 7px;
      width: 25px;
      height: 25px;
    }

    .form_radio_label {
      width: 420px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; 
      @media screen and (max-width: ($breakpoint-sm - 0.02)) {
        padding-left: 30px;       
      }
    }

    label:last-of-type {
      margin-left: 20px;
    }

    label:first-of-type {
      margin-right: calc(100% - 340px);
    }
  }

  .form_privacy_section_marketing.openText,
  .form_privacy_section_marketing_third_party.openText {
    .collapse-arrow {
      display: none;
    }

    .expanded-arrow {
      display: block;
    }

    .form_radio_label {
      overflow: initial;
      white-space: inherit;
      width: 100%;
      margin-right: 0;
      padding-right: 40px;
    }
  }

  &.form-rtl {
    .form_privacy_section_marketing {
      .collapse-arrow,
      .expanded-arrow {
        right: auto;
        left: 7px;
      }

      .form_radio_label {
        @media screen and (max-width: ($breakpoint-sm - 0.02)) {
          padding-left: 30px;
          padding-right: 0;
        }
      }

      label:last-of-type {
        margin-right: 20px;
      }
      label:first-of-type {
        margin-left: calc(100% - 340px);
        margin-right: 20px;
      }

      &.openText .form_radio_label {
        margin-left: 0;
      }
    }

    .form_privacy_section_marketing_third_party {
      .collapse-arrow,
      .expanded-arrow {
        right: auto;
        left: 7px;
      }

      .form_radio_label {
        @media screen and (max-width: ($breakpoint-sm - 0.02)) {
          padding-left: 30px;
          padding-right: 0;
        }
      }

      label:last-of-type {
        margin-right: 20px;
      }
      label:first-of-type {
        margin-left: calc(100% - 340px);
        margin-right: 20px;
      }

      &.openText .form_radio_label {
        margin-left: 0;
      }
    }
  }
}

.three-cols .form_short_field_section {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.form-rtl {
  .three-cols .form_short_field_section {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.content-switcher-content0.active .form.container.contactUs form fieldset.form_section.three-cols .form_short_field_section label {
  left: 32px;
}

.combolingua {
  padding-right: 9px !important;
  padding-left: 0px !important;
  margin-left: -8px;
}

main > .form.contactUs,
div:not(.right-col) > .form.contactUs {
  .form_title_section {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 390px;
    height: 100%;
    background-color: $color-1554FF;
    color: $color-white;
    margin-top: -69px;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 60px;

    .form_title {
      @include font-sizes($GillSansRegular, 48, 62);
      margin-bottom: 24px;
    }

    .form_subtitle {
      @include font-sizes($RobotoRegular, 18, 23);
      color: $color-white;
      margin-left: 0;
    }
  }
}

main > .form.form-rtl.contactUs,
div:not(.right-col) > .form.form-rtl.contactUs {
  .form_title_section {
    right: 0;
    left: auto;
  } 
}

.form.form-rtl.contactUs {
  justify-content: left;
}


.form.contactUs {
  display: flex;
  justify-content: right;
  position: relative;

  fieldset {
    textarea {
      @include font-sizes($RobotoRegular, 18, 24);
      background: #fafafa;
      padding-top: 10px;
      padding-left: 20px;
      outline: none;
      height: 144px;
      width: 100%;
    }

    label[for="fxb_7d557fd1-f68f-4c85-827f-bc6c8baa0fdf_Fields_30a7c11b-7493-4671-9645-172ce5164bbf__Value"] {
      position: absolute;
      top: 80px;
      margin-top: 12px;
    }
  }

  fieldset.active {
    textarea {
      border: 2px solid $color-1554FF;
      background-color: white;
    }
  }

  fieldset.success-input {
    textarea {
      border: 1px solid #287d3c;
    }
  }

  fieldset:has(textarea) {
    position: relative;
    padding-right: 0;
    flex: 100%;
    clear: both !important;
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 0px !important;
  }

  fieldset.active:has(textarea) {
    label {
      top: -8px !important;
    }
  }

  .captcha {
    margin-bottom: 16px;
    margin-top: 33px;
  }

  fieldset.form_privacy_section.privacy_section {
    position: relative;

    img {
      display: none;
    }

    div.invalid {
      position: absolute;
      z-index: 999;
      top: 22px;
      width: 100%;
    }
  }
  &.form-rtl form {
    padding: 69px 32px 69px 0;
  }

  form {
    @include row();
    padding: 69px 0 69px 32px;
    // padding-left: 36px;
    width: calc(100% - 377px);

    .form_consent_version {
      display: none;
    }
    
  }

  fieldset:not(.form_title_section) {
    flex-basis: 50%;
    max-width: 50%;
    margin: 0;
    padding-right: 16px;
  }

  &.form-rtl {
    fieldset:not(.form_title_section):not(.privacy_section):not(.form_section) {
      padding-right: 16px;
    }

    fieldset:nth-child(even):not(.form_title_section) {
      padding-right: 0px !important;
    }

    .privacy_section {
      padding-right: 0px !important;
      padding-left: 16px;
    }
  }

  @media screen and (min-width: $breakpoint-m) {
    // &.form-rtl {
    //   fieldset:not(.form_title_section) {
    //     padding-right: 0;
    //     // padding-left: 16px !important;
    //   }

    //   .submit_section {
    //     padding-left: 0;
    //   }

    //   fieldset:nth-child(even):not(.form_title_section):not(.submit_section) {
    //     padding-left: 16px !important;
    //   }

    //   fieldset:nth-child(n + 7):not(.form_title_section):not(.submit_section) {
    //     padding-left: 16px !important;
    //   }
    // }
  }

  @media screen and (min-width: $breakpoint-sm) and (max-width: ($breakpoint-m - 0.02)) {
    &.form-rtl {
      fieldset:not(.form_title_section) {
        padding-right: 0;
        // padding-left: 16px !important;
      }

      .submit_section {
        padding-left: 0;
      }

     
    }
  }

  // fieldset:nth-child(even):not(.form_title_section) {
  //   padding-left: 0 !important;
  // }

  // fieldset:nth-child(n + 7):not(.form_title_section) {
  //   padding-left: 0 !important;
  // }

  fieldset.form_privacy_section_marketing,
  fieldset.form_privacy_section_marketing_third_party {
    // @include column(12);
    margin-top: 30px;
    max-width: 100%;
    flex-basis: 100%;
    // margin-left: 0 !important;
    // border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    display: flex;
    flex-flow: wrap !important;
    flex-direction: column;
    position: relative;
    padding-right: 0;

    .expanded-arrow {
      display: none;
    }

    &::before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: #eee;
    }

    .collapse-arrow,
    .expanded-arrow {
      cursor: pointer;
      position: absolute;
      top: 17px;
      right: 7px;
      width: 25px;
      height: 25px;
    }

    .form_radio_label {
      width: 420px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

        @media screen and (max-width: ($breakpoint-sm - 0.02)) {
          padding-right: 30px;
        }
    }

    label:last-of-type {
      margin-left: 20px;
    }

    label:first-of-type {
      margin-right: calc(100% - 340px);
    }

    // BUG 166470
    @media screen and (max-width: ($breakpoint-sm - 0.02)) {
      label {
        margin-right: 0px !important;
        margin-left: 0px !important;
        width: 100%;
      }
    }

    .invalid {
      p {
        margin-top: 0 !important;
      }
    }
  }

  &.form-rtl {
    .form_privacy_section_marketing {
      .collapse-arrow,
      .expanded-arrow {
        right: auto;
        left: 7px;
      }

      .form_radio_label {
        @media screen and (max-width: ($breakpoint-sm - 0.02)) {
          padding-left: 30px;
          padding-right: 0;
        }
      }

      label:last-of-type {
        margin-right: 0px;
      }
      label:first-of-type {
        margin-left: calc(100% - 340px);
        margin-right: 0px;
      }

      &.openText .form_radio_label {
        margin-left: 0;
        margin-right: 0;
        padding-right: 0;
        padding-left: 40px;
      }
    }

    .form_privacy_section_marketing_third_party {
      .collapse-arrow,
      .expanded-arrow {
        right: auto;
        left: 7px;
      }

      .form_radio_label {
        @media screen and (max-width: ($breakpoint-sm - 0.02)) {
          padding-left: 30px;
          padding-right: 0;
        }
      }

      label:last-of-type {
        margin-right: 0px;
      }
      label:first-of-type {
        margin-left: calc(100% - 340px);
        margin-right: 0px;
      }

      &.openText .form_radio_label {
        margin-left: 0;
        margin-right: 0;
        padding-right: 0;
        padding-left: 40px;
      }
    }
  }

  .form_privacy_section_marketing.openText,
  .form_privacy_section_marketing_third_party.openText {
    .collapse-arrow {
      display: none;
    }

    .expanded-arrow {
      display: block;
    }

    .form_radio_label {
      overflow: initial;
      white-space: inherit;
      width: 100%;
      margin-right: 0;
      padding-right: 40px;
    }
  }

  fieldset.form_privacy_section,
  fieldset.submit_section {
    flex: 100%;
    max-width: 100%;
    width: 100%;
  }

  fieldset {
    @include column(6);

    select:not(:nth-child(1)),
    input:not(:nth-child(1)) {
      background-color: #fafafa;
      padding-left: 17px;
      margin-bottom: 33px;
      height: 48px;
      width: 100%;
    }

    &:not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(.form_privacy_section_marketing) label {
      @include font-sizes($RobotoRegular, 16, 24);
      position: absolute;
      margin-left: 0;
      top: 12px;
      left: 16px;
      transition: top 0.25s;
    }
  }

  &.form-rtl {
    fieldset {
      select:not(:nth-child(1)),
      input:not(:nth-child(1)) {
        padding-left: 0;
        padding-right: 17px;
      }
      &:not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(.form_privacy_section_marketing) label {
        margin-right: 0;
        left: auto;
        right: 16px;
      }
    }
  }

  fieldset {
    &:not(.form_privacy_section):not(.form_privacy_section_marketing):not(.form_privacy_section_marketing_third_party) {
      &.active {
        input {
          background-color: $color-white;
          outline-color: $color-1554FF;
        }

        select {
          background-color: $color-white;
          outline-color: $color-1554FF;
        }

        label {
          @include font-size(13, 17);
          background-color: $color-white;
          color: $color-1554FF;
          top: -8px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }

    &.success-input:not(.form_privacy_section_marketing):not(.form_privacy_section_marketing_third_party) {
      input,
      select {
        border: 1px solid #287d3c;
      }

      &:not(.form_privacy_section):not(.form_privacy_section_marketing):not(.form_privacy_section_marketing_third_party) label {
        background: $color-white;
        color: #287d3c;
      }
    }
  }

  fieldset.error-input {
    input,
    select {
      background-color: $color-white;
      border: 1px solid #da1414;
    }
  }

  fieldset.form_privacy_section_marketing_third_party.privacy_section div.invalid,
  fieldset.form_privacy_section_marketing.privacy_section div.invalid {
    position: relative;
    top: 15px;
    width: 100%;
  }
}

@media screen and (max-width: ($breakpoint-m - 0.02)) {
  .content-switcher-content1.active
    fieldset:nth-child(even):not(.form_title_section):not(.form_privacy_section_marketing_third_party):not(.form_privacy_section_marketing)
    label {
    left: 24px;
  }

  .content-switcher-content1.active
    .form-rtl
    fieldset:nth-child(even):not(.form_title_section):not(.form_privacy_section_marketing_third_party):not(.form_privacy_section_marketing)
    label {
    right: 24px;
    left: inherit;
  }

  .form.contactUs
    > form
    > fieldset:nth-child(even):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section) {
    padding-right: 8px;
    padding-left: 0;
  }

  .form.contactUs
    > form
    > fieldset:nth-child(odd):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section) {
    padding-left: 8px;
    padding-right: 0;

    label {
      left: 24px;
    }
  }

  .form.contactUs.form-rtl
    > form
    > fieldset:nth-child(odd):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section) {
    padding-right: 8px;
    padding-left: 0;
  }

  .form.contactUs.form-rtl
    > form
    > fieldset:nth-child(even):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section) {
    padding-left: 8px;
    padding-right: 0;

    label {
      right: 24px;
      left: inherit;
    }
  }

  .content-switcher-content0.active .form.container.contactUs form fieldset.form_section.full .form_field_section {
    padding-left: 0;
  }

  .content-switcher-content0.active .form.container.contactUs form .form_section .form_field_section:last-of-type,
  .content-switcher-content0.active .form.container.contactUs form fieldset.form_section.three-cols .form_short_field_section {
    padding-left: 8px;

    label {
      left: 24px;
    }
  }

  .content-switcher-content0.active .form.container.contactUs.form-rtl form .form_section .form_field_section:last-of-type,
  .content-switcher-content0.active .form.container.contactUs form fieldset.form_section.three-cols .form_short_field_section {
    padding-left: 8px;

    label {
      right: 24px;
      left: inherit;
    }
  }

  .content-switcher-content0.active .form.container.contactUs form fieldset.form_section.full .form_field_section label {
    left: 32px;
  }

  .content-switcher-content0.active .form.container.contactUs.form-rtl form fieldset.form_section.full .form_field_section label {
    right: 32px;
    left: inherit;
  }

  .content-switcher-content0.active .form.container.contactUs form fieldset.form_section.three-cols .form_short_field_section {
    padding-right: 8px;
  }

  .form.contactUs {
    form {
      width: calc(100% - 370px);
    }
  }

  .form.contactUs > form {
    padding-left: 0;
  }

  .form_privacy_section {
    display: flex;
    position: relative;

    div.invalid {
      position: absolute;
      top: 36px;
    }
  }
}

@media screen and (max-width: ($breakpoint-m - 0.02)) {
  .form.container.contactUs {
    fieldset:not(.form_title_section) {
      padding-right: 8px;
    }

    fieldset:nth-child(even):not(.form_title_section) {
      padding-left: 8px;
      padding-right: 0;
    }

    fieldset:nth-child(n + 7):not(.form_title_section) {
      padding-left: 0;
    }

    form {
      width: 100%;
      margin: auto;
      padding-left: 0;

      .form_title_section {
        position: relative;
        flex-basis: calc(100% - 0px);
        max-width: 100%;
        margin: 0;
        margin-bottom: 48px;
        padding-bottom: 48px;
        padding-left: 16px;
        padding-right: 16px;
        height: auto;

        h1 {
          @include font-size(32, 42);
        }

        p {
          margin-bottom: 10px;
        }
      }
    }

    fieldset button {
      margin-top: 26px;
    }
  }

  .form_privacy_section_marketing label:not(:first-of-type),
  .form_privacy_section_marketing_third_party label:not(:first-of-type) {
    margin-right: 0;
  }

  .form_privacy_section_marketing.openText .expanded-arrow,
  .form_privacy_section_marketing_third_party.openText .expanded-arrow {
    top: 27px !important;
  }

  .form.contactUs form {
    padding-top: 0;
  }

  .form.contactUs fieldset.form_privacy_section.privacy_section div.invalid {
    top: 38px;
  }

  .form_privacy_section {
    p {
      @include font-sizes($RobotoRegular, 13, 17);
      color: #424242;
    }
  }
}

@media screen and (max-width: ($breakpoint-sm - 0.02)) {
  .form.contactUs {
    form {
      .form_title_section {
        padding: 48px 24px !important;
      }
    }
  }

  .content-switcher-content0.active .form.container.contactUs form fieldset.form_section.full .form_field_section label,
  .content-switcher-content0.active .form.container.contactUs form .form_section .form_field_section:last-of-type label {
    left: 16px;
  }

  .content-switcher-content0.active .form.form-rtl.container.contactUs form fieldset.form_section.full .form_field_section label,
  .content-switcher-content0.active .form.form-rtl.container.contactUs form .form_section .form_field_section:last-of-type label {
    right: 16px;
    left: inherit;
  }

  .form {
    .form_section > p,
    form > p {
      padding-left: 70px;
    }
  }

  .form.form-rtl {
    .form_section > p,
    form > p {
      padding-left: 0px;
      padding-right: 70px;
    }
  }

  .form.contactUs .form_privacy_section_marketing.openText .expanded-arrow,
  .form.contactUs .form_privacy_section_marketing_third_party.openText .expanded-arrow {
    top: 50px !important;
  }
}

@media screen and (max-width: 454px) {
  .form.contactUs {
    &.form-rtl {
      fieldset.form_privacy_section_marketing,
      fieldset.form_privacy_section_marketing_third_party {
        label:first-of-type {
          margin-left: calc(100% - 230px);
          margin-right: 0;
        }

        .form_radio_label {
          width: 290px;
          padding-left: 30px;
          padding-right: 0;
        }
      }
    }
    fieldset.form_privacy_section_marketing,
    fieldset.form_privacy_section_marketing_third_party {
      label:first-of-type {
        margin-right: calc(100% - 230px);
      }

      .form_radio_label {
        width: 290px;
        padding-right: 30px;
      }
    }
  }
}

@media screen and (max-width: ($breakpoint-sm - 0.02)) {
  .form.contactUs
    > form
    > fieldset:nth-child(odd):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section),
  .form.contactUs
    > form
    > fieldset:nth-child(even):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section),
  .form.container.contactUs
    fieldset:nth-child(odd):not(.form_title_section):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ),
  .form.container.contactUs
    fieldset:nth-child(even):not(.form_title_section):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ) {
    padding-left: 0;
    padding-right: 0;

    label {
      left: 16px;
    }
  }

  .form.contactUs.form-rtl
    > form
    > fieldset:nth-child(odd):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section),
  .form.contactUs.form-rtl
    > form
    > fieldset:nth-child(even):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ):not(.form_title_section),
  .form.container.contactUs.form-rtl
    fieldset:nth-child(odd):not(.form_title_section):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ),
  .form.container.contactUs.form-rtl
    fieldset:nth-child(even):not(.form_title_section):not(.form_privacy_section):not(.form_privacy_section_marketing_third_party):not(
      .form_privacy_section_marketing
    ) {
    padding-left: 0;
    padding-right: 0;

    label {
      left: inherit;
      right: 16px;
    }
  }

  .form.container.contactUs {
    form {
      margin: auto !important;
    }

    fieldset {
      flex-basis: 100%;
      max-width: 100%;

      input:not(:nth-child(1)) {
        margin-bottom: 16px !important;
      }

      .invalid:not(label) {
        top: -15px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-sm) and (max-width: ($breakpoint-l - 0.02)) {
  .form.contactUs {
    form {
      padding: 69px 0 69px 36px;
    }

    &.form-rtl {
      form {
        padding: 69px 36px 69px 0;
      }
    }
  } 
}

.newsletter {
  display: flex;

  .form_privacy_section {
    .form_checkbox_label {
      padding-bottom: 0;
    }

    img {
      display: none;
    }
  }

  form fieldset {
    max-width: 100%;
  }

  .privacy_section {
    margin-top: 10px;

    &::before {
      content: "";
      background-color: #eee;
      bottom: 0;
      position: absolute;
      height: 1px;
      width: 100%;
    }
  }

  fieldset {
    margin-bottom: 0;
  }

  fieldset.form_privacy_section_marketing {
    display: flex;
    flex-flow: wrap !important;
    flex-direction: column;
    padding-bottom: 15px;

    .expanded-arrow {
      display: none;
    }

    .collapse-arrow {
      cursor: pointer;
      position: absolute;
      top: 17px;
      right: 0;
      height: 25px;
      width: 25px;
    }

    .form_radio_label {
      width: 320px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    label:first-of-type {
      margin-right: calc(100% - 325px);
    }
  }

  .form_privacy_section_marketing.openText {
    .collapse-arrow {
      display: none;
    }

    .expanded-arrow {
      display: block;
      position: absolute;
      top: 40px;
      right: 0;
      height: 25px;
      width: 25px;
    }

    .form_radio_label {
      overflow: initial;
      white-space: inherit;
      width: 90%;
      margin-right: 0;
    }
  }

  fieldset.form_privacy_section_marketing_third_party {
    padding-bottom: 15px;

    .expanded-arrow {
      display: none;
    }

    .collapse-arrow {
      cursor: pointer;
      position: absolute;
      top: 17px;
      right: 0;
      height: 25px;
      width: 25px;
    }

    .form_radio_label {
      width: 320px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    label:first-of-type {
      margin-right: calc(100% - 325px);
    }
  }

  .form_privacy_section_marketing_third_party.openText {
    .collapse-arrow {
      display: none;
    }

    .expanded-arrow {
      display: block;
      position: absolute;
      top: 40px;
      right: 0;
      height: 25px;
      width: 25px;
    }

    .form_radio_label {
      overflow: initial;
      white-space: inherit;
      width: 90%;
      margin-right: 0;
    }
  }

  fieldset.form_privacy_section.privacy_section {
    position: relative;

    input[type="checkbox"]::before {
      left: 0;
    }

    div.invalid {
      position: absolute;
      top: 22px;
      width: 100%;
    }
  }

  .form_placeholder_wrapper {
    width: 40%;
    margin-right: 48px;
  }

  .form_image {
    width: 60%;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  form {
    .form_title_section {
      margin-top: 80px;

      .form_subtitle {
        @include font-sizes($RobotoRegular, 18, 23);
        color: #000000;
        margin-left: 0;
      }

      h1 {
        @include font-sizes($GillSansRegular, 48, 62);
        margin-bottom: 14px;
      }

      h4 {
        font-weight: 600;
      }
    }

    fieldset .invalid:not(label) {
      position: relative;
      top: -24px;
    }
  }

  p {
    @include font-sizes($RobotoRegular, 18, 23);
    padding-bottom: 24px;
  }

  fieldset .invalid:not(label) {
    position: relative;
    top: -33px;
  }
}

@media screen and (max-width: ($breakpoint-l - 0.02)) {
  .newsletter {
    fieldset.form_privacy_section_marketing .form_radio_label {
      margin-right: 70px;
    }

    fieldset.form_privacy_section_marketing_third_party .form_radio_label {
      margin-right: 70px;
    }
  }
}

@media screen and (max-width: ($breakpoint-m - 0.02)) {
  .newsletter {
    fieldset.form_privacy_section_marketing {
      .form_radio_label {
        margin-right: 500px;
      }

      label:not(:first-of-type) {
        margin-right: 18px;
      }
    }

    fieldset.form_privacy_section_marketing_third_party {
      .form_radio_label {
        margin-right: 500px;
      }

      label:not(:first-of-type) {
        margin-right: 18px;
      }
    }

    &.form-rtl {
      .form_privacy_section_marketing label:not(:first-of-type) {
        margin-left: 18px;
        margin-right: 0;
      }

      .form_privacy_section_marketing_third_party label:not(:first-of-type) {
        margin-left: 18px;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: ($breakpoint-m - 0.02)) {
  .newsletter {
    form fieldset .invalid:not(label) {
      position: relative;
      top: -15px;
    }

    display: flex;
    flex-direction: column-reverse !important;

    .form_placeholder_wrapper {
      width: 100%;
    }

    .form_image {
      width: 100%;
    }

    &.form-rtl {
      .form_privacy_section_marketing label:not(:first-of-type) {
        margin-left: 18px;
        margin-right: 0;
      }

      .form_privacy_section_marketing_third_party label:not(:first-of-type) {
        margin-left: 18px;
        margin-right: 0;
      }
    }

    .form_privacy_section_marketing label:not(:first-of-type) {
      margin-right: 18px;
    }

    .form_privacy_section_marketing_third_party label:not(:first-of-type) {
      margin-right: 18px;
    }

    form {
      .form_title_section {
        margin-top: 48px;
      }
    }

    fieldset.form_privacy_section.privacy_section {
      div.invalid {
        top: 40px;
      }
    }

    .form_image img {
      height: auto;
    }

    fieldset input:not(:nth-child(1)) {
      margin-bottom: 16px;
    }
  }
}

@media screen and (min-width: ($breakpoint-m - 0.02)) {
  form:has(.form_poland_gdpr_section) fieldset,
  form:not(.formBrazil):has(.privacy_section)fieldset {
    padding-right: 16px !important;
  }
}

@media screen and (max-width: ($breakpoint-sm - 0.02)) {
  .layout-two-columns-section .col-9.one-col-section {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .layout-two-columns-section .one-col-section > section > .container {
    padding-left: 8px !important;
    padding-right: 0px !important;
  }

  .commercialLeadPoland .sitecore-jss-form__error-list p {
    margin-top: 0px !important;
  }

  .content-switcher-content1.active fieldset.form_poland_gdpr_section fieldset {
    padding-right: 0px !important;
  }

  .commercialLeadPoland {
    margin-top: 10px !important;
    margin-left: 16px !important;

    .form_poland_gdpr_section {
      max-width: calc(100% - 32px) !important;
    }
  }

  .mandatoryCheckParent .sitecore-jss-form__error-list.invalid {
    top: 40px !important;
  }

  main > .contactUs fieldset:nth-child(even):not(.form_poland_gdpr_section, .privacy_section):not(.mandatoryCheckParent),
  main > .contactUs fieldset:nth-child(odd):not(.form_poland_gdpr_section, .privacy_section):not(.mandatoryCheckParent) {
    padding-right: 0px;
  }

  .form:has(.form_poland_gdpr_section) .privacy_section div.invalid {
    top: 40px !important;
  }

  .form.container.newsletter {
    form .form_title_section h1 {
      font-size: 32px;
    }

    fieldset.form_privacy_section_marketing {
      label:first-of-type {
        margin-right: calc(100% - 294px);
      }

      .form_radio_label {
        width: 220px;
      }
    }

    fieldset.form_privacy_section_marketing_third_party {
      label:first-of-type {
        margin-right: calc(100% - 294px);
      }

      .form_radio_label {
        width: 220px;
      }
    }
  }
}
