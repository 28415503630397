@import "../@shared/flexbox";
@import "../variables";

.big-container {
  box-sizing: border-box;
  max-width: 1500px;
  min-height: 100vh;
  position: relative;
}

@media screen and (min-width: 640px) {
  .big-container {
    padding-right: 4 * $padding-base;
    padding-left: 4 * $padding-base;
  }
}

@media screen and (max-width: 639px) {
  .big-container {
    padding-right: 3 * $padding-base;
    padding-left: 3 * $padding-base;
  }
}

/* stylelint-disable */

$columns: 12;
$outerMargin: 32px;
$gutter: 32px;

$content-full-width: 100%;
$content-width-1024: 1024px - 60px; // we keep 20px offset and substracting 28px on each side (2 x 30px = 60px)
$content-width-1500: 1500px - 60px; // we keep 20px offset and substracting 28px on each side (2 x 30px = 60px)

// max
// REVISIT Couldn't less do the calc?
$media-query-max-639: "(max-width: 639px)";
$media-query-min-640: "(min-width: 640px)";
$media-query-min-640-max-959: "(min-width: 640px) and (max-width: 959px)";
$media-query-min-960-max-1023: "(min-width: 960px) and (max-width: 1023px)";
$media-query-min-1024-max-1499: "(min-width: 1024px) and (max-width: 1499px)";
$media-query-min-1500: "(min-width: 1500px)";
$direction: 1;

/* 
 * rows
 */
@mixin row($axis: row, $wrap: wrap) {
  @include flexbox();
  @include flex-flow($axis $wrap);
  @include gutter(-1);
}

/* 
 * gutter
 */

@mixin gutter($direction: 1) {
  @media screen and (min-width: $breakpoint-l) {
    margin-left: 16px * ($direction);
    margin-right: 16px * ($direction);
  }

  @media screen and (min-width: $breakpoint-sm) and (max-width: ($breakpoint-l - 0.02)) {
    margin-left: 12px * ($direction);
    margin-right: 12px * ($direction);
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-left: 0px ; // DOCHUB FIX era 8px * ($direction)
    margin-right: 8px * ($direction);
  }
}

.cp-row {
  @include row();
  height: 100%;
  position: relative;
}

@mixin container-fluid() {
  max-width: $content-width-1500;
  margin: 0 auto;
}

.cp-u-container-fluid {
  @include container-fluid();
}

@mixin container() {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.container {
  @include container();
  max-width: 1760px;
}

.full-container {
  max-width: 1920px;
}

.container--no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.cp-full-width {
  left: 50%;
  padding: 0;
  position: relative;
  transform: translateX(-50%);
  width: 100vw;
}

/* 
 * Columns
 */

@mixin column() {
  flex-basis: 0px;
  flex-shrink: 1;
  flex-grow: 1;
  max-width: none;
  position: relative;
  @include gutter(1);
}

@mixin column($x, $columns: $columns) {
  $column: ($x/$columns) * 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: calc(#{$column} - #{$gutter});
  max-width: calc(#{$column} - #{$gutter});
  position: relative;
  @include gutter(1);
}

/* 
 * Offset
 */

@mixin offset($x, $columns: $columns) {
  $column: $x/$columns * 100%;
  margin-left: calc($column + $gutter/ 2);
}

/* 
 * grid classes and Loops
 */

// .cp-u-col {
//     @include column();
// }

// columns
// offsets

// alignments

.cp-row-start {
  justify-content: flex-start;
}

.cp-row-center {
  justify-content: center;
}

.cp-row-end {
  justify-content: flex-end;
}

.cp-row-top {
  align-items: flex-start;
}

.cp-row-middle {
  align-items: center;
}

.cp-u-row-bottom {
  align-items: flex-end;
}

.cp-row-stretch {
  align-items: stretch;
}

.cp-col-top {
  align-self: flex-start;
}

.cp-col-middle {
  align-self: center;
}

.cp-col-bottom {
  align-self: flex-end;
}

.cp-col-center {
  text-align: center;
}

.cp-col-right {
  text-align: right;
}

// Float styling
.cp-clearfix::after {
  clear: both;
  content: "";
  display: block;
  width: 100%;
}

.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1 * $padding-base;
  padding-right: 1 * $padding-base;
  // max-width: 100%;
  width: 100%;

  .one-col-section > .container {
    padding-left: 0;
    padding-right: 0;

    > * {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.row {
  display: flex;
}

@media (min-width: $breakpoint-sm) {
  .container {
    padding-left: 1.5 * $padding-base;
    padding-right: 1.5 * $padding-base;
    width: 100%;
    // max-width: 100%;
  }
}

@media (min-width: $breakpoint-sm) {
  // max-width: 100%;

  .col-1 {
    @include column(1);
  }

  .col-2 {
    @include column(2);
  }

  .col-3 {
    @include column(3);
  }

  .col-4 {
    @include column(4);
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .main-doc-hub {
  .highlights-module {
    .col-4
      {
        @include column(3);
        margin-left: 12px !important;
        margin-right: 12px !important;
      }
    }

    }

 
      .highlights-module {
        .col-4
          {
            @include column(3);
            margin-left: 12px !important;
            margin-right: 12px !important;
          }
        } 

  .col-5 {
    @include column(5);
  }

  .col-6 {
    @include column(6);
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .col-7 {
    @include column(7);
  }

  .col-8 {
    @include column(8);
  }

  .col-9 {
    @include column(9);
  }

  .col-10 {
    @include column(10);
  }

  .col-11 {
    @include column(11);
  }

  .col-12 {
    @include column(12);
  }
}

@media (min-width: $breakpoint-l) {
  .container {
    padding-left: 2 * $padding-base;
    padding-right: 2 * $padding-base;
    width: 100%;
    // max-width: 100%;
  }
}

@media (max-width: ($breakpoint-m - 0.02)) and (min-width: $breakpoint-sm) {
  .col-3 {
    @include column(4);
    flex-basis: calc(33.3% - 24px);
    max-width: calc(33.3% - 24px);
  }

  .col-4,
  .col-6 {
    flex-basis: calc(50% - 24px);
    max-width: calc(50% - 24px);
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (min-width: $breakpoint-m) and (max-width: ($breakpoint-l - 0.02)) {
  .col-3 {
    flex-basis: calc(25% - 24px);
    max-width: calc(25% - 24px);
  }

  .col-9 {
    flex-basis: calc(75% - 24px);
    max-width: calc(75% - 24px);
  }
}

@media screen and (min-width: $breakpoint-sm) and (max-width: ($breakpoint-l - 0.02)) {
  .col-12 {
    flex-basis: calc(100% - 24px);
    max-width: calc(100% - 24px);
  }

  // .col-9 {
  //   flex-basis: calc(75% - 24px);
  //   max-width: calc(75% - 24px);
  // }
}

@media (max-width: ($breakpoint-sm - 0.2)) {
  .cp-row .cp-row,
  .cp-row .cp-row > * {
    margin-left: 0;
    margin-right: 0;
  }

  .col-3 {
    @include column(6);
    flex-basis: calc(50% - 16px);
    max-width: calc(50% - 16px);
  }

  .col-4 {
    margin-left: 8px !important;
    margin-right: 8px !important;
    width: 100%;
  }

  .col-5,
  .col-6,
  .col-7 {
    flex-basis: 100% !important;
    max-width: 100% !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .col-12 {
    margin-left: 8px;
    margin-right: 8px;
    flex-basis: calc(100% - 16px);
    max-width: calc(100% - 16px);
  }
}

body.cp-debug-show-grid {
  .container {
    height: 100%;
  }

  .cp-debug-grid {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 100;
    height: 100%;
    pointer-events: none;
  }

  .container,
  .container-fluid {
    height: 100%;
    outline: 1px dotted #00f;
  }

  .cp-debug-grid [class*="col"] {
    height: 100%;
    background-color: rgba(255, 0, 0, 0.05);
    outline: 1px dotted #f0f;
  }

  .cp-row {
    outline: 1px dotted rgba(255, 0, 255, 0.25);
    height: 100%;
  }

  [class*="col"] {
    outline: 1px solid rgba(255, 0, 0, 0.5);
  }

  [class*="col"] > span {
    display: block;
    padding: 4px 0;
    background-color: rgba(255, 0, 0, 0.55);
  }

  .cp-debug {
    margin: 0;
    padding: 4px 6px;
  }

  [class*="col"] > span.small {
    font-size: 14px;
    text-align: center;
  }
}
