// @import "../node_modules/@patternfly/pfelement-onhighway/dist/pfelement";
@import "./lib/css/slick/slick.scss";
// @import "./lib/css/slick/slick-theme.scss";
@import "./styles/variables";
@import "./styles/mixins";
@import "./styles/font";
@import "./styles/headlines";
@import "./components/FormContainerModule/index.scss";

// initial theme -> to be configured in ThemeContext/index.js and index.html
// @import "../node_modules/@patternfly/pfe-styles-onhighway/dist/pfe-theme--case";
/// ===================================================
/// CONTAINERS AND BACKGROUNDS. USAGE:
/// <div class="bg-default">
///   <div class="container">
///     <p>Content</p>
///   </div>
/// </div>
/// ===================================================
* {
  touch-action: manipulation;
}

html {
  scroll-behavior: smooth;
}

body {
  color: initial;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: none) and (pointer: coarse) {
  .ReactModal__Content {
    touch-action: pan-y;
  }
}

main {
  position: relative;
}

button {
  border: none;
}

input {
  border: none;
}

fieldset {
  border: none;
}

select {
  border: none;
}

textarea {
  border: none;
}

.loading-spinner {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
}

.small,
.medium,
.high {
  position: relative;

  &:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
  }

  div,
  section,
  button {
    z-index: 10;
  }

  > img:not(.hero-slider-img):not(.page-header-img):not(.carousel-img):not(.banner-img):not(.big-card-img) {
    z-index: 10;
  }
}

.small::before {
  background: rgba(255, 255, 255, 0.1);
}

.medium::before {
  background: rgba(255, 255, 255, 0.2);
}

.high::before {
  background: rgba(255, 255, 255, 0.4);
}

.dark {
  h1,
  .description {
    color: $color-white;
  }

  &.small:before {
    background: rgba(0, 0, 0, 0.1);
  }

  &.medium:before {
    background: rgba(0, 0, 0, 0.2);
  }

  &.high:before {
    background: rgba(0, 0, 0, 0.4);
  }
}

.light {
  h1,
  .description {
    color: $color-black;
  }
}

#root {
  max-width: 1920px;
  overflow-x: hidden;
  margin: 0 auto;
  width: 100%;
  overflow-y: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

img {
  display: block;
}

.section-space {
  padding-top: 64px;
  padding-bottom: 96px;

  .cp-row > *:not(:last-of-type):not(.col-6) {
    margin-bottom: 20px;
  }
}

.modular-data-table.section-space {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 64px;
  margin-bottom: 96px;
}

.rc-anchor-invisible {
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

.vehicle-card-logo {
  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}

.btn {
  @include font-sizes($RobotoRegular, 18, 24);

  display: flex;
  padding: 14px 20px;
  justify-content: center;
  text-transform: none;
  width: 100%;
  max-width: fit-content;

  .icon svg {
    @include center-vertical(relative);
    height: 24px;
    width: auto;
    display: flex;
  }

  &:not(.btn__icon-only) {
    .icon {
      display: inherit;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &:not(.btn__icon-only):not(.btn__isRtl)>span+.icon {
    margin-right: 0;
  }

  &:not(.btn__icon-only):not(.btn__isRtl)>.icon:first-child {
    margin-left: 0;
  }

  &.btn__isRtl {
    .icon {
      transform: scaleX(-1);
    }
    &:not(.btn__icon-only)>span+.icon {
        margin-left: 0;
    }

    &:not(.btn__icon-only)>.icon:first-child {
      margin-right: 0;
    }
  }
  img {
    max-height: 25px;
  }

  + .btn {
    margin-top: 20px;
  }
}

.btn__medium {
  @include font-size(16, 24);
  padding: 6px 14px;

  .icon svg {
    height: 16px;
    width: auto;
  }
}

.btn {
  background-color: $color-1a61ff;
  color: $color-white;

  &[disabled] {
    background-color: $color-EEEEEE;
    color: $color-9E9E9E;
    pointer-events: none;

    svg,
    path {
      stroke: $color-9E9E9E;
      fill: $color-9E9E9E;
    }
  }

  &:hover {
    background-color: $color-0048E6;
  }

  &:active {
    background-color: $color-0041CE;
  }

  &.btn--dark {
    &:hover {
      background-color: $color-0048E6;
    }

    &:active {
      background-color: $color-0041CE;
    }

    &[disabled] {
      background-color: $color-424242;
      color: $color-9E9E9E;

      svg,
      path {
        stroke: $color-9E9E9E;
        fill: $color-9E9E9E;
      }
    }
  }
}

.btn__primary, .btn__secondary {
  
  svg path {
    fill: #fff;
  }

}

.btn__primary {
  // &.btn--dark svg path {
  //   fill: black;
  //  }
  &.btn--light svg path {
    fill: white;
    // stroke: white;
  }
}

.btn__secondary {
  background-color: $color-black;
  color: $color-white;

  &:hover {
    background-color: $color-424242;
  }

  &:active {
    background-color: $color-616161;
  }

  &[disabled] {
    background-color: $color-EEEEEE;
    color: $color-9E9E9E;

    svg,
    path {
      stroke: $color-9E9E9E;
      fill: $color-9E9E9E;
    }
  }

  &.btn--dark {
    background-color: $color-757575;
    color: $color-white;

    &:hover {
      background-color: $color-616161;
    }

    &:active {
      background-color: $color-424242;
    }

    &[disabled] {
      background-color: $color-424242;
      color: $color-9E9E9E;

      svg,
      path {
        stroke: $color-9E9E9E;
        fill: $color-9E9E9E;
      }
    }
  }

  svg path {
    fill: white;
  }

  // &.btn--dark svg path {
  //   fill: black;
  //   stroke: black;
  // }
}

.btn__ghost {
  color: $color-1554FF;
  background-color: $color-white;
  border: 1px solid $color-1554FF;

  svg,
  path {
    stroke: $color-1554FF;
    fill: $color-1554FF;
  }

  &.btn--dark {
    stroke: white;
    fill: white;
  }

  &:hover {
    background-color: $color-white;
    color: $color-0048E5;

    svg,
    path {
      stroke: $color-0048E5;
      fill: $color-0048E5;
    }
  }

  &:active {
    background-color: $color-0041CE;
    color: $color-white;

    svg,
    path {
      stroke: $color-white;
      fill: $color-white;
    }
  }

  &.btn--dark {
    color: $color-white;
    background-color: $color-232323;
    border: 1px solid $color-white;

    svg,
    path {
      stroke: $color-white;
      fill: $color-white;
    }

    &:hover {
      background-color: $color-232323;
      color: $color-f5f5f5;

      svg,
      path {
        stroke: $color-f5f5f5;
        fill: $color-f5f5f5;
      }
    }

    &:active {
      background-color: $color-0041CE;
      color: $color-white;

      svg,
      path {
        stroke: $color-white;
        fill: $color-white;
      }
    }
  }

  &[disabled] {
    background-color: $color-424242;
    color: $color-9E9E9E;

    svg,
    path {
      stroke: $color-9E9E9E;
      fill: $color-9E9E9E;
    }
  }
}

.btn__with-icons {
  justify-content: space-between;
}

.btn__icon-only {
  border-radius: 50%;
  padding: 14px;
  width: auto;
  max-width: 52px;

  img {
    margin: 0;
  }

  &.btn__large {
    width: 52px;
    height: 52px;
  }

  &.btn__medium {
    padding: 9px;
    width: 40px;
    height: 40px;

    svg {
      height: 20px !important;
    }
  }
}

.btn__onlytext {
  background-color: transparent;
  padding: 6px 0;

  &.btn--light {
    color: $color-1554FF;
    background-color: transparent;

    svg,
    path {
      stroke: $color-1554FF;
      fill: $color-1554FF;
    }

    &:hover {
      color: $color-0048E5;
      background-color: transparent;

      svg,
      path {
        stroke: $color-0048E5;
        fill: $color-0048E5;
      }
    }

    &:active {
      color: $color-0041CE;
      background-color: transparent;

      svg,
      path {
        stroke: $color-0041CE;
        fill: $color-0041CE;
      }
    }

    &[disabled] {
      color: $color-9E9E9E;
      background-color: transparent;

      svg,
      path {
        stroke: $color-0041CE;
        fill: $color-0041CE;
      }
    }
  }

  &.btn--dark {
    color: $color-white;
    background-color: transparent;

    &:hover {
      color: $color-f5f5f5;
      background-color: transparent;

      svg,
      path {
        stroke: $color-f5f5f5;
        fill: $color-f5f5f5;
      }
    }

    &:active {
      color: $color-e0e0e0;
      background-color: transparent;

      svg,
      path {
        stroke: $color-e0e0e0;
        fill: $color-e0e0e0;
      }
    }

    &[disabled] {
      color: $color-616161;
      background-color: transparent;

      svg,
      path {
        stroke: $color-616161;
        fill: $color-616161;
      }
    }
  }
}

@media screen and (max-width: (1136px - 0.2)) {
  .header-bar-container {
    height: 60px;
    //overflow: hidden;
  }
}

@media screen and (min-width: 1136px) {
  .header-bar-container {
    height: 73px;
    // overflow: hidden;
  }
}

//BUG ALTEZZA
.content-switcher__main-items .slick-slide[aria-hidden="true"] {
  height: 10px;
}

.btn__primary,
.btn__secondary {
  svg path {
    fill: #fff;
  }
}

.global-menu-login-form {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 6px 14px;
  background: $color-1554FF;
  cursor: pointer;

  .login-btn {
    color: $color-white;
    background: none;
    outline: none;
    border: none;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    gap: 6px;
    align-items: center;
  }

  .icon {
    height: 16px;
    width: 16px;
    margin-bottom: 3px;
  }
  
  &__mobile {
    width: 100%;

    .login-btn {
      width: 100%;
      text-align: left;
    }
  }

  &:hover,
  &__mobile:hover {
    background: $primary-button-hover;  
  }
}
